import axios from "axios";
import NProgress from "nprogress"; // progress bar
import { message, Modal } from "antd";
import "nprogress/nprogress.css";
import { getToken } from "@/utils/localStorage";
import { createHashHistory } from "history"; // 引入 createHashHistory
axios.defaults.withCredentials = true;
axios.defaults.baseURL = "/api/";
axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";
NProgress.configure({
    showSpinner: false,
});

// HTTPrequest拦截
axios.interceptors.request.use(
    (config) => {
        const isToken = config.isToken === false;
        if (getToken() && !isToken) {
            config.headers["Authorization"] = "Bearer " + getToken(); // 让每个请求携带自定义token 请根据实际情况自行修改
        }
        NProgress.start(); // start progress bar
        return config;
    },
    (error) => {    
        return Promise.reject(error);
    }
);

// HTTPresponse拦截
axios.interceptors.response.use(
    (res) => {
        NProgress.done();
        const status = Number(res.status) || 200;
        const msg = res.data?.msg || "服务端错误";
        if (status !== 200 || res.data.code === 0) {
            message.error(msg);
            return Promise.reject(new Error(msg));
        } else if (res.data.code === 401) {
            const history = createHashHistory();
            Modal.info({
                title: "提示",
                content: `${msg}`,
                cancelText: "1",
                onOk: () => {
                    history.push("/login");
                },
                okText: "重新登录",
            });
            return;
        } else return Promise.resolve(res.data);
    },
    (error) => {
        NProgress.done();
        return Promise.reject(new Error(error));
    }
);
//配置拦截器
export default axios;
