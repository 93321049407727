const AccessTokenKey = "ACCESS_TOKEN";
// ========== Token 相关 ==========

export function getToken() {
    return localStorage.getItem(AccessTokenKey);
}

export function setToken(token) {
    localStorage.setItem(AccessTokenKey, token);
}

export function removeToken() {
    localStorage.removeItem(AccessTokenKey);
}

// ========== 账号 相关 ==========

const UserKey = "USER"
export function getUser() {
    return JSON.parse(localStorage.getItem(UserKey))
}
export function setUser(user) {
    localStorage.setItem(UserKey, JSON.stringify(user));
}
export function removeUser() {
    localStorage.removeItem(UserKey)
}
export function getRole() {
    return JSON.parse(localStorage.getItem(UserKey))?.role
}
export function getName() {
    return JSON.parse(localStorage.getItem(UserKey))?.username
}

// ========== 字典 相关 ==========
const DickKey = "DICT";
//设置字典项
export function setDict(dictData) {
    if (dictData instanceof Array !== true && dictData.length > 0) return;
    let data = {};
    for (let i = 0; i < dictData.length; i++) {
        data[dictData[i].type] = dictData[i].values;
    }
    localStorage.setItem(DickKey, JSON.stringify(data));
}
//获取字典项
export function getDict(type = "") {
    if (type) {
        let data = JSON.parse(localStorage.getItem(DickKey));
        return data[type];
    } else return JSON.parse(localStorage.getItem(DickKey)) || {};
}
