import React, { useState, Suspense } from "react";
import { useHistory, useLocation, HashRouter, Route, Redirect, Switch } from "react-router-dom";
import { Layout, Menu, Alert } from "antd";
import routers from "@/router/index";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import Login from "@/views/login";
import NotFound from "@/views/NotFound";
import "./layout.css";
import router from "../router";
import MyDropdown from "./myDropdown";
import Loding from './loding';
import { getToken, getRole, getName } from "@/utils/localStorage";
import { getOptNameType, getOptRemarkType } from "@/utils/dict"
const { Header, Content, Sider } = Layout;
const menuWidth = "200px";
export default function MyLayout() {
    const history = useHistory();
    const location = useLocation();
    const [collapsed, setCollapsed] = useState(false);
    const firstName = location.pathname.substring(location.pathname.indexOf("/"), location.pathname.indexOf("/", 1)) || "/blog";
    const lastName = location.pathname.substring(location.pathname.lastIndexOf("/"));
    const [activeHead, setActiveHead] = useState(firstName);
    let headerIndex = router.findIndex((item) => item.path === activeHead) === -1 ? 0 : router.findIndex((item) => item.path === activeHead);
    // 检查用户是否已经登录
    const isAuthenticated = getToken() || null;
    // 左侧边栏
    const leftMenu = router[headerIndex]?.children.map((item) => {
        if (item.meta && item.meta.hidden) return null;
        else {
            let disabled = false
            if (item.meta && item.meta.disabled) disabled = true
            return {
                key: item.path, icon: item.icon, label: item.name, disabled,
                children:
                    item.children && item.children.length > 0
                        ? item.children.map((item1) => {
                            return { key: item1.path, icon: item1.icon, label: item1.name, disabled };
                        })
                        : null,
            };
        }
    });
    //上边栏
    const topMenu = router.map((item) => {
        if (item.meta && item.meta.hidden) return null;
        let userRole = getRole() || ''
        if (!userRole) return
        let ifAllowedRoles = true
        if (item.meta && item.meta.allowedRoles?.length > 0) {
            ifAllowedRoles = item.meta.allowedRoles.includes(userRole)
        }
        return { key: item.path, icon: item.icon, label: item.name, disabled: !ifAllowedRoles };
    });
    //路由跳转
    const navTo = (e) => {
        let str = "/" + location.pathname.split("/")[2];
        if (str === e.key) return;
        history.push(router[headerIndex].path + e.key);
    };
    return (
        <HashRouter>
            <Layout className="layout" style={{ color: "black" }}>
                <Sider collapsible={true} collapsed={collapsed} width={menuWidth} className="site-layout-background" style={{ overflowY: "auto", zIndex: 999, minHeight: "100vh" }}>
                    <div className="logo-warp" style={{ float: "left", display: "flex", justifyContent: "center", alignItems: "center", width: "100%", margin: "10px 0" }}>
                        <img className="logo" alt="logo" src={require("../static/img/girl2.jpg")} style={{ width: "50%", height: "50px", objectFit: "scale-down" }} />
                    </div>
                    <Menu className="left-menu-warp" theme="dark" mode="inline" defaultOpenKeys={[lastName]} defaultSelectedKeys={[lastName]} openKeys={[lastName]} selectedKeys={[lastName]} style={{ height: "100%", borderRight: 0 }} items={leftMenu} onClick={navTo}></Menu>
                </Sider>
                <Layout>
                    <Header className="header" style={{ display: "flex", width: "100%", padding: 0, paddingRight: "20px", backgroundColor: "#fff" }}>
                        {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                            className: "trigger", onClick: () => { setCollapsed(!collapsed) },
                            style: { padding: "0 10px 0 24px", fontSize: "18px", lineHeight: "64px", cursor: "pointer", transition: "color 0.3s", backgroundColor: "#fff" },
                        })}
                        {/* 上边栏 */}
                        <Menu style={{ flex: 1 }} mode="horizontal" defaultOpenKeys={[firstName]} defaultSelectedKeys={[firstName]} items={topMenu} onClick={(e) => { setActiveHead(e.key) }}>
                        </Menu>
                        <div style={{ marginRight: '20px' }}>{getName()}</div>
                        <MyDropdown></MyDropdown>
                    </Header>
                    <Content className="site-layout-background" style={{ padding: 24, margin: "0  16px 0 16px", minHeight: 280, boxSizing: 'border-box' }}>
                        <Suspense fallback={<Loding />}>
                            <Alert
                                message={`账号类型为【 ${getOptNameType('account_type', getRole())}】，${getOptRemarkType('account_type', getRole())}`}
                                closable style={{ marginBottom: '20px' }}
                            />
                            <Switch>
                                {isAuthenticated &&
                                    routers.map((item) => {
                                        if (item.children && (!item.meta || !item.meta.hiddenLabel)) {
                                            const ChildRoutes = item.children.map((item1) => {
                                                if (item1.children) {
                                                    const GrandRoutes = item1.children.map((item2) => {
                                                        return <Route key={item.path + item1.path + item2.path} path={item.path + item1.path + item2.path} component={item2.component} exact />;
                                                    });
                                                    return [<Route key={item.path + item1.path} path={item.path + item1.path} component={item1.component} exact />, GrandRoutes];
                                                } else {
                                                    return <Route key={item.path + item1.path} path={item.path + item1.path} component={item1.component} exact />;
                                                }
                                            });
                                            return [<Route key={item.path} path={item.path} component={item.component} exact />, ChildRoutes];
                                        } else {
                                            return <Route key={item.path} path={item.path} component={item.component} exact />;
                                        }
                                    })}
                                <Route path="/login" component={Login} exact></Route>
                                <Route path="/404" component={NotFound} exact></Route>
                                <Redirect from="/" to="/blog/home" exact />
                                {isAuthenticated ? <Redirect from="*" to="/404" exact /> : <Redirect from="*" to="/login" exact></Redirect>}
                                <Redirect from="*" to="/404" exact />
                            </Switch>
                        </Suspense>
                    </Content>
                </Layout>
            </Layout>
        </HashRouter>
    );
}
