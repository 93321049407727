import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Form, Input, Button, message } from "antd";
import "./login.scss";
import api from "@/api/system/user";
import { setToken, setUser, } from "@/utils/localStorage";
import api_option from "@/api/system/option";
import { setDict } from "@/utils/localStorage";

export default function Login() {
    const [loading, setloading] = useState(false);
    const [form] = Form.useForm();
    const history = useHistory();
    //登录
    const login = async () => {
        const valid = await form.validateFields();
        if (!valid) return;
        setloading(true);
        try {
            const res = await api.login(form.getFieldsValue(true));
            message.success(res.msg);
            let res_opt = await api_option.getoption();
            setToken(res.data.token);
            setUser(res.data.user);
            setDict(res_opt.data);
            history.push("/blog/home");
        } catch (err) {
            setloading(false);
        }
    };

    return (
        <div className="warp">
            <div className="login-warp">
                <div className="login-title">管理系统</div>
                <Form form={form} name="form" initialValues={{ remember: true }} autoComplete="off">
                    <Form.Item label="账号：" name="username" rules={[{ required: true, message: "请输入账号！" }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="密码" name="password" rules={[{ required: true, message: "请输入密码！" }]}>
                        <Input.Password />
                    </Form.Item>
                </Form>
                <Button loading={loading} type="primary" onClick={login} htmlType="submit" style={{ width: "100%" }}>
                    登录
                </Button>
            </div>
        </div>
    );
}
