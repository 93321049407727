import request from "../axios";

const api = {
    getoption(params) {
        return request({
            url: "/blog/option/get",
            method: "get",
            params: params,
        });
    },
    getOptionOr(data) {
        return request({
            url: "/blog/option/getOr",
            method: "post",
            data: data,
        });
    },

    addoption(data) {
        return request({
            url: "/blog/option/add",
            method: "post",
            data: data,
        });
    },

    updateoption(data) {
        return request({
            url: "/blog/option/update",
            method: "post",
            data: data,
        });
    },

    remove(data) {
        return request({
            url: "/blog/option/remove",
            method: "post",
            data: data,
        });
    },
};

export default api;
