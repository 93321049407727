import { UserOutlined, LaptopOutlined, LineChartOutlined, NotificationOutlined, MenuUnfoldOutlined, MenuFoldOutlined, SkinOutlined, ShareAltOutlined, HighlightOutlined, SlidersOutlined, HddOutlined } from "@ant-design/icons";
import { lazy } from 'react'

const router = [
    {
        path: "/blog",
        name: "我的主页",
        icon: <SkinOutlined />,
        children: [
            {
                path: "/home",
                name: "首页",
                icon: <UserOutlined />,
                component: lazy(() => import('@/views/blog/home/index')),
            },
            {
                path: "/blog",
                name: "博客",
                icon: <LaptopOutlined />,
                component: lazy(() => import('@/views/blog/blog/index')),
            },
            {
                path: "/shares",
                name: "分享",
                icon: <ShareAltOutlined />,
                component: lazy(() => import('@/views/blog/shares/index')),
            },
            {
                path: "/collect",
                name: "收藏",
                icon: <HddOutlined />,
                component: lazy(() => import('@/views/blog/collect/index')),
            },
            {
                path: "/mdEdit/:id/:type",
                name: "编辑",
                meta: { hidden: true },
                component: lazy(() => import('@/views/blog/blog/component/mdEdit')),
            },
            {
                path: "/recode",
                name: "记录",
                icon: <HighlightOutlined />,
                component: lazy(() => import('@/views/blog/record/index')),
            },
        ],
    },
    {
        path: "/ability",
        name: "功能模块",
        icon: <NotificationOutlined />,
        meta: { hidden: true },
        children: [
            {
                path: "/welcome",
                name: "首页",
                meta: { hidden: true },
                icon: <LaptopOutlined />,
                component: lazy(() => import('@/views/ability/welcome/index')),
            },
            {
                path: "/echarts",
                name: "图表",
                meta: { hidden: true },
                icon: <LineChartOutlined />,
                component: lazy(() => import('@/views/ability/echarts/index')),
            },
            {
                path: "/three",
                name: "3d模型",
                icon: <LineChartOutlined />,
                component: lazy(() => import('@/views/ability/three/index')),
            },
            {
                path: "/drag",
                name: "图片拖拽",
                icon: <LineChartOutlined />,
                component: lazy(() => import('@/views/ability/drag/index')),
            },
            {
                path: "/dragList",
                name: "列表拖拽",
                icon: <LineChartOutlined />,
                component: lazy(() => import('@/views/ability/dragList/index')),
            },
            {
                path: "/domToImg",
                name: "dom转图片",
                icon: <LineChartOutlined />,
                component: lazy(() => import('@/views/ability/domToImg/index')),
            },
        ],
    },
    {
        path: "/project",
        name: "项目模块",
        icon: <NotificationOutlined />,
        children: [
            {
                path: "/work",
                name: "工作项目",
                icon: <LaptopOutlined />,
                component: lazy(() => import('@/views/project/project/index')),
            },
            {
                path: "/my",
                name: "我的项目",
                icon: <UserOutlined />,
                component: lazy(() => import('@/views/project/project/index')),
            },
       
            {
                path: "/other",
                name: "其他项目",
                icon: <ShareAltOutlined />,
                component: lazy(() => import('@/views/project/project/index')),
            },
        ],
    },
    {
        path: "/system",
        name: "系统管理",
        meta: {
            allowedRoles: ['admin']
        },
        icon: <MenuUnfoldOutlined />,
        children: [
            {
                path: "/user",
                name: "用户管理",
                component: lazy(() => import('@/views/system/user/index')),
                icon: <UserOutlined />,
            },
            {
                path: "/dictionary",
                name: "字典管理",
                component: lazy(() => import('@/views/system/dictionary/index')),
                icon: <SlidersOutlined />,
            },
            {
                path: "/menu",
                role: ["superAdmin"],
                name: "菜单管理",
                component: lazy(() => import('@/views/system/menu/index')),
                meta: {
                    disabled: true,
                },
                icon: <MenuFoldOutlined />,
            },
            {
                path: "/option/:_id",
                name: "字典管理子项",
                meta: { hidden: true },
                component: lazy(() => import('@/views/system/dictionary/option')),
                icon: <SlidersOutlined />,
            },
        ],
    },
];

export default router;
