import api_option from "@/api/system/option";
import { setDict, getDict } from "./localStorage";
//字典中根据"option,value"获取name
export function getOptName(option, value) {
    let name = "";
    let index = option.findIndex((item) => item.value === value);
    name = index !== -1 ? option[index]["name"] : "不存在";
    return name;
}

//字典中根据"type,value"获取name
export function getOptNameType(type, value) {
    let name = "";
    try {
        const dict = getDict();
        console.log('getDict',dict)
        let option = dict[type];
        let index = option.findIndex((item) => item.value === value);
        name = index !== -1 ? option[index]["label"] : "不存在";
    } catch (err) {
        name = '不存在'
    }
    return name;
}

//字典中根据"tag,value"获取name
export function getNameFromTagArr(type, tagArr) {
    let nameArr = [];
    try {
        const dict = getDict();
        let option = dict[type];
        tagArr.forEach((item, index) => {
            let findindex = option.findIndex((citem) => citem.value === item);
            nameArr[index] = findindex !== -1 ? option[findindex]["label"] : "不存在";
        })

    } catch (err) {
        nameArr = ['不存在']
    }
    return nameArr;
}
//字典中根据"type,value"获取remark
export function getOptRemarkType(type, value) {
    let name = "";
    try {
        const dict = getDict();
        let option = dict[type];
        let index = option.findIndex((item) => item.value === value);
        name = index !== -1 ? option[index]["remark"] : "不存在";
    } catch (err) {
        name = '不存在'
    }
    return name;
}


//获取字典(需要参数，type:'blog_type,share_type')
export async function getDictFromDb(typeArrString) {

    let res = null
    if (!typeArrString) {
        res = await api_option.getoption()
    }
    else {
        res = await api_option.getOptionOr({ type: typeArrString });
    }
    setDict(res.data);
}