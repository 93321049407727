import React from "react";
import { Menu, Dropdown, message } from "antd";
import { HomeOutlined, SettingOutlined, LogoutOutlined, CaretDownOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

export default function UserMenuList() {
    const history = useHistory();

    const loginOut = (e) => {
        localStorage.clear();
        history.push("/login");
        message.success("退出登录成功");
    };
    const goHome = () => {
        history.push("/blog/home");
    }

    const items = [
        { label: (<div onClick={goHome}>首页</div>), key: '/welcome', icon: <HomeOutlined /> },
        // { label: (<div >个人设置</div>), key: '/accountSettings', icon: <SettingOutlined /> },
        { label: (<div onClick={loginOut}>退出登录</div>), key: '/loginOut', icon: <LogoutOutlined /> },
    ];
    return (
        <Dropdown menu={{ items }}>
            <div style={{ cursor: "pointer", display: 'flex', alignItems: 'center' }}>
                <img src={require("../static/img/user_default.jpg")} alt="user" style={{ width: "40px", height: "40px", borderRadius: "10px" }} />
                <CaretDownOutlined style={{ color: "#999", fontSize: "12px", transform: "translateY(15px)" }} />
            </div>
        </Dropdown>
    );
}
