/*
 * @Author: hwd
 * @Description:   
 * @FilePath: \my-react\src\views\NotFound.js
 */
import React, { Component } from 'react'

export default class NotFound extends Component {
    render() {
        return (
            <div>
                404页面
            </div>
        )
    }
}
