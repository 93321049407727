import React, { useEffect, } from "react";
import Layout from "@/layouts/layout";
import Login from "@/views/login";
import NotFound from "@/views/NotFound";
import { HashRouter, Route, Switch } from "react-router-dom";
import { App } from 'antd';
import './index.css'
// import "antd/dist/antd.min.css";
import { getDictFromDb } from "@/utils/dict";

export default function APP() {
    useEffect(() => {
        getDictFromDb("share_type,blog_type,project_type,code_type,account_type,blog_tag,collect_type");
    }, []);
    return (
        <App>
            <HashRouter>
                <Switch>
                    <Route path="/login" component={Login} exact></Route>
                    <Route path="/404" component={NotFound} exact></Route>
                    <Layout />
                </Switch>
            </HashRouter>
        </App>
    );
}
